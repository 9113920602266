var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c(
        "a-form",
        {
          ref: "formLogin",
          staticClass: "user-layout-login",
          attrs: { form: _vm.form },
          on: { submit: _vm.handleSubmit }
        },
        [
          _vm.showLoginErrorInfo
            ? _c("a-alert", {
                staticStyle: { "margin-bottom": "24px" },
                attrs: {
                  type: "error",
                  showIcon: "",
                  message: _vm.showLoginErrorInfo
                }
              })
            : _vm._e(),
          _c(
            "a-form-item",
            [
              _c(
                "a-input",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "username",
                        {
                          rules: [{ required: true, message: "请输入帐户名" }],
                          validateTrigger: "change"
                        }
                      ],
                      expression:
                        "[\n          'username',\n          {rules: [{ required: true, message: '请输入帐户名' }], validateTrigger: 'change'}\n        ]"
                    }
                  ],
                  attrs: {
                    size: "large",
                    type: "text",
                    placeholder: "请输入账户"
                  },
                  on: {
                    focus: function($event) {
                      _vm.usernameIcon = require("@/assets/svg/select-user.svg")
                    },
                    blur: function($event) {
                      _vm.usernameIcon = require("@/assets/svg/user.svg")
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "user",
                    attrs: {
                      slot: "prefix",
                      src: _vm.usernameIcon,
                      alt: "user"
                    },
                    slot: "prefix"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-input-password",
                {
                  directives: [
                    {
                      name: "decorator",
                      rawName: "v-decorator",
                      value: [
                        "password",
                        {
                          rules: [{ required: true, message: "请输入密码" }],
                          validateTrigger: "change"
                        }
                      ],
                      expression:
                        "[\n          'password',\n          {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'change'}\n        ]"
                    }
                  ],
                  attrs: { size: "large", placeholder: "请输入密码" },
                  on: {
                    focus: function($event) {
                      _vm.passwordIcon = require("@/assets/svg/select-lock.svg")
                    },
                    blur: function($event) {
                      _vm.passwordIcon = require("@/assets/svg/lock.svg")
                    }
                  }
                },
                [
                  _c("img", {
                    staticClass: "user",
                    attrs: {
                      slot: "prefix",
                      src: _vm.passwordIcon,
                      alt: "user"
                    },
                    slot: "prefix"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "code" },
            [
              _c(
                "a-form-item",
                [
                  _c(
                    "a-input",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "usercode",
                            {
                              rules: [
                                { required: true, message: "请输入人机验证码" }
                              ],
                              validateTrigger: "blur"
                            }
                          ],
                          expression:
                            "[\n            'usercode',\n            {rules: [{ required: true, message: '请输入人机验证码' }], validateTrigger: 'blur'}\n          ]"
                        }
                      ],
                      staticClass: "code-input",
                      attrs: {
                        size: "large",
                        type: "text",
                        placeholder: "请输入人机验证码"
                      },
                      on: {
                        focus: function($event) {
                          _vm.vercodeIcon = require("@/assets/svg/select-code.svg")
                        },
                        blur: function($event) {
                          _vm.vercodeIcon = require("@/assets/svg/code.svg")
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "user",
                        attrs: {
                          slot: "prefix",
                          src: _vm.vercodeIcon,
                          alt: "user"
                        },
                        slot: "prefix"
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "code-img",
                  staticStyle: { position: "relative", background: "#ddd" }
                },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.vercodeImgSrc,
                        expression: "vercodeImgSrc"
                      }
                    ],
                    attrs: { src: _vm.vercodeImgSrc },
                    on: {
                      click: function($event) {
                        return _vm.refVercode()
                      }
                    }
                  }),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isOverdue,
                          expression: "isOverdue"
                        }
                      ],
                      staticClass: "vercode-mask",
                      on: {
                        click: function($event) {
                          return _vm.refVercode()
                        }
                      }
                    },
                    [_vm._v("已过期 请刷新")]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            [
              _c(
                "a-checkbox",
                {
                  model: {
                    value: _vm.isAutoLogin,
                    callback: function($$v) {
                      _vm.isAutoLogin = $$v
                    },
                    expression: "isAutoLogin"
                  }
                },
                [_vm._v("自动登录")]
              ),
              _c(
                "a",
                {
                  staticClass: "forge-password",
                  staticStyle: { float: "right" }
                },
                [_vm._v("忘记密码?")]
              )
            ],
            1
          ),
          _c(
            "a-form-item",
            { staticClass: "submit" },
            [
              _c(
                "a-button",
                {
                  staticClass: "login-button",
                  attrs: {
                    size: "large",
                    type: "primary",
                    htmlType: "submit",
                    loading: _vm.loginBtnLoadingFlag
                  }
                },
                [_vm._v("登录 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }